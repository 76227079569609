import axios from "@/plugins/axios";


const state = {
  settings: {}
}
export const mutationTypes = {
  setSettings: "[settings] setSettings",
}
const mutations = {
  [mutationTypes.setSettings](state, settings) {
    state.settings = settings;
  }
}
export const actionTypes = {
  getSettings: "[settings] getSettings",
  updateBannerProfitMargin: "[settings] updateBannerProfitMargin",
  cleanBannerProfitMargin: "[settings] cleanBannerProfitMargin",
}
const actions = {
  async [actionTypes.getSettings]({ commit }) {
    try {
      const settings = await axios.get(`/platform/api/v1/settings`).then(e => e.data);
      commit(mutationTypes.setSettings, settings);
    } catch (error) {
      console.error(error);
    }
  },

  async [actionTypes.updateBannerProfitMargin]({commit}, payload) {
    try {
      const settings = await axios.post(`/platform/api/v1/settings/banners/profit-margin`, payload).then(e => e.data);
      commit(mutationTypes.setSettings, settings);
      return {ok: true}
    } catch (e) {
      return {error: e.response?.data?.error}
    }
  },

  async [actionTypes.cleanBannerProfitMargin]({ commit }, payload) {
    try {
      const settings = await axios.post(`/platform/api/v1/settings/banners/profit-margin/clear`, payload).then(e => e.data);
      commit(mutationTypes.setSettings, settings);
    } catch (error) {
      console.error(error);
    }
  }
}
export const getterTypes = {
  settings: "[settings] settings",
}
const getters = {
  [getterTypes.settings]: state => state.settings,
}

export default {
  state,
  mutations,
  actions,
  getters,
};
